<template>
  <div>
    <feather-icon title="Edit" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="onEdit" />
    <feather-icon title="Delete" icon="TrashIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="showDeletePopup=true" />
    
    <vs-prompt
      title="Delete Branch"
      accept-text="Delete"
      @accept="onDelete"
      :active.sync="showDeletePopup">

        <div class="vx-row mt-2 mb-2">
          <div class="vx-col w-full">
            <div class="">Are you sure you want to delete the branch - {{params.data.name}}?</div>
          </div>
        </div>
    </vs-prompt>

  </div>
</template>

<script>
import _ from 'lodash'

export default {
  data () {
    return {
      showDeletePopup: false
    }
  },
  computed: {
  },
  methods: {
    onEdit () {
      this.params.context.componentParent.editBranch(this.params.data.id)
    },
    onDelete () {
      this.params.context.componentParent.deleteBranch(this.params.data.id)
    }
  }
}
</script>
